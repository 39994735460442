import React from 'react';

const Rewind = ({ content, url }) => {
  return (
    <div className="rewind animate-left">
      <p>{content}</p>
      <a rel="noreferrer" target="_blank" href={url}>
        <span className="material-icons">play_circle</span>
        Zobacz teraz
      </a>
    </div>
  );
};

export default Rewind;

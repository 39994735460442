import React from 'react';

const BasicInfo = ({ description, date }) => {
  return (
    <div className="basic-info animate-right">
      <p className="basic-info__description">{description}</p>
      <p className="basic-info__date">{date}</p>
      <hr />
    </div>
  );
};

export default BasicInfo;

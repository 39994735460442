import React from 'react';
import ReactMarkdown from 'react-markdown';
import Header from '../Header';
import Tile from '../Tile';

const CMSSection = ({ details, animationDirection, isOdd, handleTileClick }) => {
  return (
    <section className={`festival-section festival-section--${isOdd ? 'odd' : 'even'}`}>
      <div className="tiles">
        <div className={`tiles__header animate-${animationDirection}`}>
          <Header animationDirection={animationDirection} color={details.colorHeader} text={details.header} />
          <ReactMarkdown allowDangerousHtml>{details.description}</ReactMarkdown>
        </div>
        {details.tiles.map((t) => (
          <Tile
            key={t.tile.name}
            setActiveTile={() => handleTileClick(t.tile)}
            animationDirection={animationDirection}
            text={t.tile.name}
            image={t.tile.photo}
            date={t.tile.date}
          />
        ))}
      </div>
    </section>
  );
};

export default CMSSection;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { IoCalendarSharp as CalendarIcon } from 'react-icons/io5';

const Tile = ({ date, text, image, animationDirection, setActiveTile }) => {
  const style = image ? { backgroundImage: `url(${image.mediaItemUrl})` } : null;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={() => setActiveTile()} style={style} className={`tile animate-${animationDirection}`}>
      <div className="tile__color" />
      {date && (
        <div className="tile__date">
          <span>{date}</span>
          <CalendarIcon />
        </div>
      )}
      <p className="tile__text">{text}</p>
      <hr />
    </div>
  );
};

export default Tile;

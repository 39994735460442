/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { IoCalendarSharp as CalendarIcon } from 'react-icons/io5';
import useClickOutsideHandler from '../../../hooks/useClickOutsideHandler';
import Spotlight from '../Spotlight';

const TileDrawer = ({ tile, isActive, handleDrawerClose }) => {
  const drawerRef = React.createRef();
  useClickOutsideHandler(drawerRef, () => handleDrawerClose());

  return (
    <div ref={drawerRef} className={`drawer ${isActive ? 'active' : ''}`}>
      <Spotlight />
      <Spotlight />
      <span onClick={handleDrawerClose} className="material-icons close-icon">
        close
      </span>
      {tile && (
        <>
          {tile.photo ? <img src={tile.photo.mediaItemUrl} alt={tile.name} /> : <div className="fake-img" />}
          <h2>{tile.name}</h2>
          <ReactMarkdown className="drawer__content" allowDangerousHtml>
            {tile.description}
          </ReactMarkdown>
          <span className="background-name">{tile.name}</span>
          {tile.date && (
            <div className="drawer__date">
              <span>{tile.date}</span>
              <CalendarIcon />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TileDrawer;

import React from 'react';
import Header from '../Header';
import BasicInfo from '../BasicInfo';
import Rewind from '../Rewind';

const StartingSection = ({ details }) => (
  <section className="festival-section festival-section--landing">
    <div className="gradient-bar" />
    <Header animationDirection="left" color={details.colorHeader} text={details.header} />
    <BasicInfo date={details.date} description={details.description} />
    <Rewind content={details.previousEdition.description} url={details.previousEdition.link} />
  </section>
);

export default StartingSection;

import React from 'react';

const Header = ({ text, color, animationDirection }) => {
  return (
    <h1 className={`animate-${animationDirection}`}>
      <span>{color}</span>
      {` ${text}`}
    </h1>
  );
};

export default Header;
